import { useCallback, useState } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";

export const useVideoSDK = (props) => {
  const [isJoined, setIsJoined] = useState(false);

  const onMeetingJoined = useCallback(
    () => {
      if (typeof props?.onMeetingJoined === 'function') {
        props?.onMeetingJoined();
      }

      if (!isJoined) setIsJoined(true);
    },
    [isJoined]
  );
  const onMeetingLeft = useCallback(() => {
    if (typeof props?.onMeetingLeft === 'function') {
      props?.onMeetingLeft();
    }

    if (isJoined) setIsJoined(false);
  }, [isJoined, props]);

  const meetingSource = useMeeting({
    ...props,
    onMeetingJoined,
    onMeetingLeft
  });

  return {
    ...meetingSource,
    isJoined
  };
};
