import React, { useRef, useEffect, Fragment, useState, useMemo, useCallback } from 'react';
import {
  useParticipant,
  usePubSub,
  useMediaDevice,
  useMeeting
  // getNetworkStats
} from '@videosdk.live/react-sdk';
import Colors from 'okadoc-component-ui/lib/Colors';

// Libs
import { isMobile, mobileBreakPoint } from 'libs/okaBrowserCheck';
import useChat from 'libs/hooks/useChat';
import { CONSULTATION_STATUS, EVENT_PUBSUB } from 'libs/constant';
import { getFullDatetime } from 'libs/date';
import { getQualityScore } from 'libs/network';
import { useGlobalConfigStore } from 'zustandStore';
import { getStorage } from 'libs/storage';
import useMediaStream from 'libs/hooks/useMediaStream';

// Components
import ToolBox from 'components/Toolbox';
import NetworkIndicatorTable from './network-indicator-table';
import cn from 'classnames';
import { Microphone, MicrophoneSlash, WifiHigh, WifiMedium, WifiLow, PictureInPicture } from 'phosphor-react';
import { Indicator, IndicatorGroup } from './indicator-wrapper';

import DoctorPreview from '../../../assets/images/avatar-doctor.png';
import { useTranslation } from 'react-i18next';
import { useVideoSDK } from 'libs/hooks/useVideoSDK';
import VideoPlayer from 'components/video-player';

import useTracking from 'libs/hooks/useTracking';
import { VirtualBackgroundProcessor } from '@videosdk.live/videosdk-media-processor-web';
import { getVirtualBackgroundUrl, isUseVirtualBackground } from 'libs/virtual-background';

const chunk = arr => {
  const newArr = [];
  while (arr.length) newArr.push(arr.splice(0, 3));
  return newArr;
};

const getIndicatorColor = score => {
  return score > 7 ? '#04850A' : score > 4 ? '#CA9E00' : '#C20000';
};

const IndicatorsRemoteParticipant = props => {
  const participantId = props?.participantId ?? '';

  const [score, setScore] = useState(0);
  const [audioStats, setAudioStats] = useState({});
  const [videoStats, setVideoStats] = useState({});
  const [networkTable, setNetworkTable] = useState(false);

  const networkBarEnabled = useGlobalConfigStore(state => state.networkBarEnabled);

  const { webcamStream, micStream, micOn, isLocal, getVideoStats, getAudioStats } = useParticipant(participantId);
  const statsIntervalIdRef = useRef();

  const NetworkIndicator = useMemo(() => {
    if (score > 7) return WifiHigh;
    else if (score > 4) return WifiMedium;

    return WifiLow;
  }, [score]);

  const MuteIndicator = useMemo(() => {
    if (micOn) {
      return Microphone;
    }
    return MicrophoneSlash;
  }, [micOn]);

  const networkIndicatorColor = useMemo(() => getIndicatorColor(score), [score]);

  const updateStats = async () => {
    let stats = [];

    if (webcamStream) {
      stats = await getVideoStats();
    } else if (micStream) {
      stats = await getAudioStats();
    }

    const score = stats ? (stats.length > 0 ? getQualityScore(stats[0]) : 100) : 100;

    setScore(score);

    let audioStats = [];
    let videoStats = [];

    if (webcamStream || micStream) {
      videoStats = await getVideoStats();
      audioStats = await getAudioStats();
    }

    setAudioStats(audioStats);
    setVideoStats(videoStats);
  };

  useEffect(() => {
    if (networkBarEnabled) {
      if (webcamStream || micStream) {
        updateStats();

        if (statsIntervalIdRef.current) {
          clearInterval(statsIntervalIdRef.current);
        }

        statsIntervalIdRef.current = setInterval(updateStats, 10000);
      } else {
        if (statsIntervalIdRef.current) {
          clearInterval(statsIntervalIdRef.current);
          statsIntervalIdRef.current = null;
        }
      }

      return () => {
        if (statsIntervalIdRef.current) clearInterval(statsIntervalIdRef.current);
      };
    }
  }, [webcamStream, networkBarEnabled, micStream]);

  const handleNetworkClick = () => {
    setNetworkTable(current => !current);
  };

  const handleOpenNetworkDetail = e => {
    e.stopPropagation();
    handleNetworkClick();
  };

  return (
    <div className="network-indicator-wrapper">
      <div className="network-indicator">
        <Indicator style={{ marginRight: 8 }}>
          <MuteIndicator color={Colors.text.WhiteText} size={24} />
        </Indicator>
        <Indicator onClick={handleOpenNetworkDetail} backgroundColor={networkIndicatorColor}>
          <NetworkIndicator color={Colors.text.WhiteText} size={24} />
        </Indicator>
        <button onClick={props.togglePiP} className="pip-button">
          <PictureInPicture size={24} weight="fill" />
        </button>
      </div>
      {networkTable && (
        <NetworkIndicatorTable isLocal={isLocal} audioStats={audioStats} videoStats={videoStats} score={score} />
      )}
    </div>
  );
};

const IndicatorsLocalParticipant = props => {
  const { webcamStream, micStream, micOn, isLocal, getVideoStats, getAudioStats } = useParticipant(
    props?.participantId
  );
  const statsIntervalIdRef = useRef();
  // const [networkStats, setNetworkStats] = useState({ downloadSpeed: 0, uploadSpeed: 0 });

  const networkBarEnabled = useGlobalConfigStore(state => state.networkBarEnabled);
  const globalMicOn = useGlobalConfigStore(state => state.micOn);

  const [score, setScore] = useState(0);

  const updateStats = async () => {
    let stats = [];

    if (webcamStream) {
      stats = await getVideoStats();
    } else if (micStream) {
      stats = await getAudioStats();
    }

    const score = stats ? (stats.length > 0 ? getQualityScore(stats[0]) : 100) : 100;

    setScore(score);
  };

  useEffect(() => {
    if (networkBarEnabled) {
      if (webcamStream || micStream) {
        updateStats();

        if (statsIntervalIdRef.current) {
          clearInterval(statsIntervalIdRef.current);
        }

        statsIntervalIdRef.current = setInterval(updateStats, 10000);
      } else {
        if (statsIntervalIdRef.current) {
          clearInterval(statsIntervalIdRef.current);
          statsIntervalIdRef.current = null;
        }
      }

      return () => {
        if (statsIntervalIdRef.current) clearInterval(statsIntervalIdRef.current);
      };
    }
  }, [webcamStream, networkBarEnabled, micStream]);

  // useEffect(() => {
  //   const updateNetworkStats = async () => {
  //     try {
  //       const options = { timeoutDuration: 45000 };
  //       const stats = await getNetworkStats(options);

  //       setNetworkStats({
  //         downloadSpeed: stats['downloadSpeed'], // Mb/s
  //         uploadSpeed: stats['uploadSpeed'] // Mb/s
  //       });
  //     } catch (error) {
  //       console.log('Error in networkStats: ', error);
  //     }
  //   };

  //   updateNetworkStats();

  //   const intervalId = setInterval(updateNetworkStats, 60000); // 10 seconds

  //   return () => clearInterval(intervalId);
  // }, []);

  const NetworkIndicator = useMemo(() => {
    if (score > 7) return WifiHigh;
    else if (score > 4) return WifiMedium;

    return WifiLow;
  }, [score]);

  const MuteIndicator = useMemo(() => {
    const isActiveLocalMic = isLocal && micOn;

    if (isActiveLocalMic) {
      return Microphone;
    }
    return MicrophoneSlash;
  }, [isLocal, micOn, globalMicOn]);

  const networkIndicatorColor = useMemo(() => getIndicatorColor(score), [score]);

  return (
    <IndicatorGroup
      style={{ display: 'flex', alignItems: 'center' }}
      top={props?.shouldShowSmallContainer ? 4 : 10}
      left={10}
    >
      <Indicator small={props?.shouldShowSmallContainer}>
        <MuteIndicator color={Colors.text.WhiteText} size={props?.shouldShowSmallContainer ? 16 : 24} />
      </Indicator>
      <Indicator small={props?.shouldShowSmallContainer} backgroundColor={networkIndicatorColor}>
        <NetworkIndicator color={Colors.text.WhiteText} size={props?.shouldShowSmallContainer ? 16 : 24} />
      </Indicator>
      {/* Speed table inline styles */}
      {/* {!props?.shouldShowSmallContainer && (
        <div
          style={{
            marginLeft: '10px',
            backgroundColor: 'rgba(51, 51, 51, 0.5)',
            color: '#ffffff',
            padding: '4px 8px',
            borderRadius: '4px'
          }}
        >
          <table style={{ borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ padding: '4px 8px', textAlign: 'left', fontSize: '14px', fontWeight: 'bold' }}>
                  Speed
                </th>
                <th style={{ padding: '4px 8px', textAlign: 'left', fontSize: '14px', fontWeight: 'bold' }}>
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ padding: '4px 8px', textAlign: 'left', fontSize: '14px' }}>Download</td>
                <td style={{ padding: '4px 8px', textAlign: 'left', fontSize: '14px' }}>
                  {networkStats.downloadSpeed} Mbps
                </td>
              </tr>
              <tr>
                <td style={{ padding: '4px 8px', textAlign: 'left', fontSize: '14px' }}>Upload</td>
                <td style={{ padding: '4px 8px', textAlign: 'left', fontSize: '14px' }}>
                  {networkStats.uploadSpeed} Mbps
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )} */}
    </IndicatorGroup>
  );
};

const ParticipantView = ({
  participantId,
  leave,
  toggleView,
  setToggleView,
  hasMoreParticipant,
  webCamList,
  micList,
  speakerList,
  setMicList,
  setWebCamList,
  setSpeakerList,
  selectedSpeaker,
  setSelectedSpeaker
}) => {
  const { publish: publishChat } = usePubSub(EVENT_PUBSUB.CHAT);
  const {
    value: { doctor, patient, isDoctor, appointment },
    action
  } = useChat();
  const { publish } = usePubSub(EVENT_PUBSUB.PARTICIPANTS_STATUS, {});
  const meeting = useVideoSDK();
  const { t } = useTranslation();

  const webcamRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const screenShareRef = useRef(null);
  const micRef = useRef(null);
  const webcamDeviceId = useGlobalConfigStore(state => state.webcamDeviceId);
  const participantStatus = useGlobalConfigStore(state => state.participantStatus);
  const leavedScreenStates = useGlobalConfigStore(state => state.leavedScreenStates);
  const toggleState = useGlobalConfigStore(state => state.toggleState);
  const setWebcamDeviceId = useGlobalConfigStore(state => state.setWebcamDeviceId);
  const setMicDeviceId = useGlobalConfigStore(state => state.setMicDeviceId);
  const networkBarEnabled = useGlobalConfigStore(state => state.networkBarEnabled);
  const globalWebcamOn = useGlobalConfigStore(state => state.webcamOn);
  const [toggleShowDeviceList, setToggleShowDeviceList] = useState(false);
  const [toggleShowMicDeviceList, setToggleShowMicDeviceList] = useState(false);
  const [toggleShowSpeakerDeviceList, setToggleShowSpeakerDeviceList] = useState(false);

  const videoProcessor = useRef();

  const tracking = useTracking({
    from: 'ParticipantsView'
  });

  const { webcamStream, micStream, webcamOn, screenShareOn, screenShareStream, micOn, isLocal, setQuality } =
    useParticipant(participantId);

  const { localMicOn, localWebcamOn } = useMeeting({
    // Note: This is not needed as we are passing the processed stream directly during meeting initialization
    // onMeetingJoined: async () => {
    //   processLocalVideo();
    // }
  });

  // Note: This is not needed as we are passing the processed stream directly during meeting initialization
  // const processLocalVideo = async () => {
  //   let mediaStream;
  //   if (isDoctor) {
  //     mediaStream = await getVideoTrack(doctorCameraConfig);
  //   } else {
  //     mediaStream = await getVideoTrack(patientCameraConfig);
  //   }
  //   const processedStream = await processVideoStream(mediaStream);
  //   meeting?.changeWebcam(processedStream);
  //   return true;
  // };

  const processVideoStream = useCallback(
    async stream => {
      if (isUseVirtualBackground(isDoctor)) {
        if (!videoProcessor.current.ready) {
          await videoProcessor.current.init();
        }
        const config = {
          type: 'image',
          imageUrl: getVirtualBackgroundUrl()
        };
        const processedStream = await videoProcessor.current.start(stream, config);
        return processedStream;
      } else {
        return stream;
      }
    },
    [isDoctor]
  );

  const { getVideoTrack } = useMediaStream();

  const doctorCameraConfig = {
    optimizationMode: 'motion',
    cameraId: webcamDeviceId ? webcamDeviceId : null,
    encoderConfig: 'h540p_w720p',
    multiStream: true,
    facingMode: isMobile ? 'user' : 'environment'
  };

  const patientCameraConfig = {
    optimizationMode: 'motion',
    cameraId: webcamDeviceId ? webcamDeviceId : null,
    encoderConfig: 'h720p_w1280p',
    multiStream: false,
    facingMode: isMobile ? 'user' : 'environment'
  };

  const { getMicrophones, getCameras, getPlaybackDevices } = useMediaDevice({
    onDeviceChanged
  });

  useEffect(() => {
    setQuality('high');
  }, [webcamStream]);

  let debounceTimeout;

  async function onDeviceChanged() {
    if (debounceTimeout) clearTimeout(debounceTimeout);

    debounceTimeout = setTimeout(async () => {
      console.log('Updating List called ===>');
      const newMicList = await getMicrophones();
      const newWebcamList = await getCameras();
      const newSpeakersList = await getPlaybackDevices();

      console.log('New Camera List ===> ', newWebcamList);

      setMicList(newMicList);
      setWebCamList(newWebcamList);
      setSpeakerList(newSpeakersList);
    }, 300); // Adjust the delay (in milliseconds) as needed
  }

  const [portrait, setPortrait] = useState(false);

  const handleLeave = useCallback(
    () => {
      const remoteUser = !isDoctor ? doctor : patient;
      const userName = isDoctor ? remoteUser?.title : [remoteUser?.first_name, remoteUser?.last_name].join(' ');

      const eventEndCall = {
        ...(!isDoctor ? { patient: userName } : { practitioner: userName }),
        format: 'video_end',
        message: 'video_end',
        text: 'video_end',
        time: getFullDatetime,
        sender: !isDoctor ? 'patient' : 'doctor'
      };
      const eventPayload = JSON.stringify(eventEndCall);

      action.saveChat({
        appointmentNumber: appointment.appointmentNumber,
        message: eventEndCall
      });
      publishChat(eventPayload, { persist: false });

      // keep the previous state
      toggleState({
        leavedScreenStates: { webcamOn, micOn },
        isPatientJoinTheCall: false
      });

      leave({
        webcamOn,
        micOn
      });

      tracking.trackEvent('leave_room', isDoctor ? 'doctor' : 'patient', {
        webcamOn,
        micOn,
        action: 'leave_room_button'
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appointment.appointmentNumber, doctor, isDoctor, leave, micOn, patient, webcamOn]
  );

  const webcamMediaStream = useMemo(() => {
    if (webcamOn && !isLocal) {
      const mediaStream = new MediaStream();
      if (webcamStream) {
        mediaStream.addTrack(webcamStream.track);
        mediaStreamRef.current = mediaStream;
        return mediaStream;
      }
    }

    return;
  }, [webcamOn, isLocal, webcamStream]);

  const webcamMediaStreamLocal = useMemo(() => {
    if (webcamOn && globalWebcamOn) {
      const mediaStream = new MediaStream();
      if (webcamStream) {
        mediaStream.addTrack(webcamStream.track);
        mediaStreamRef.current = mediaStream;
        return mediaStream;
      }
    }

    if (!globalWebcamOn) meeting?.disableWebcam();

    return;
  }, [webcamOn, webcamStream, isLocal]);

  const screenShareMediaStream = useMemo(() => {
    if (screenShareOn) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(screenShareStream.track);
      return mediaStream;
    }
  }, [screenShareStream, screenShareOn]);

  const handleClickOutside = () => {
    setToggleShowDeviceList(show => false);
    setToggleShowMicDeviceList(show => false);
    setToggleShowSpeakerDeviceList(show => false);
  };

  const handleShowDeviceList = e => {
    e.stopPropagation();
    setToggleShowDeviceList(show => !show);
  };

  const handleShowMicDeviceList = e => {
    e.stopPropagation();
    setToggleShowMicDeviceList(show => !show);
  };
  const handleShowSpeakerDeviceList = e => {
    e.stopPropagation();
    setToggleShowSpeakerDeviceList(show => !show);
  };

  const handleChangeCamera = async cameraId => {
    const isWebcamDeviceIdPresent = webCamList.some(device => device.deviceId === webcamDeviceId);
    if (cameraId) {
      setWebcamDeviceId(cameraId);
      setToggleShowDeviceList(false);
      toggleState({ webcamOn: true });

      // Update the config with the new cameraId directly
      const config = isDoctor ? doctorCameraConfig : patientCameraConfig;
      const updatedConfig = {
        ...config,
        cameraId: cameraId
      };

      const track = await getVideoTrack(updatedConfig);
      // videoRef.current = track;
      if (isWebcamDeviceIdPresent) {
        const processedStream = await processVideoStream(track);
        meeting?.changeWebcam(processedStream);
      } else {
        const processedStream = await processVideoStream(track);
        meeting?.changeWebcam(processedStream);
      }
    }
  };

  const handleChangeMic = micId => {
    if (micId) {
      setMicDeviceId(micId);
      setToggleShowMicDeviceList(false);
      toggleState({ micOn: true });
      meeting?.changeMic(micId);
      tracking.trackEvent('enable_audio', isDoctor ? 'doctor' : 'patient', {
        micOn: true,
        webcamOn: useGlobalConfigStore.getState().webcamOn,
        action: 'main_participant_view_toolbox_change_device'
      });
    }
  };

  const handleChangeSpeaker = speakerId => {
    if (speakerId) {
      setSelectedSpeaker(speakerId);
      setToggleShowSpeakerDeviceList(false);
      console.log('Selected Speaker ', speakerId);
      // micRef.current.setSinkId(selectedSpeaker.deviceId);
      setAudioOutputDevice(speakerId);
    }
  };

  const setAudioOutputDevice = selectedSpeaker => {
    const audioTags = Array.from(document.getElementsByTagName('audio'));
    audioTags.forEach(tag => {
      tag.setSinkId(selectedSpeaker);
    });
  };

  const checkAndUpdatePortrait = () => {
    if (webcamStream) {
      const { height, width } = webcamStream.track.getSettings();
      console.log('[debug]: getSettings ', webcamStream.track.getSettings());
      if (height > width && !portrait) {
        setPortrait(true);
      } else if (height < width && portrait) {
        setPortrait(false);
      }
    } else {
      setPortrait(false);
    }
  };

  const handleLocalToggleMic = () => {
    meeting?.toggleMic();
    if (!micOn) {
      toggleState({ micOn: true });
      tracking.trackEvent('enable_audio', isDoctor ? 'doctor' : 'patient', {
        micOn: true,
        webcamOn: useGlobalConfigStore.getState().webcamOn,
        action: 'main_participant_view_toolbox'
      });
    } else {
      toggleState({ micOn: false });
      tracking.trackEvent('disable_audio', isDoctor ? 'doctor' : 'patient', {
        micOn: false,
        webcamOn: useGlobalConfigStore.getState().webcamOn,
        action: 'main_participant_view_toolbox'
      });
    }
  };

  const handleLocalToggleWebcam = async () => {
    if (localWebcamOn) {
      meeting?.disableWebcam();
    } else {
      const track = await getVideoTrack(isDoctor ? doctorCameraConfig : patientCameraConfig);
      const processedStream = await processVideoStream(track);
      meeting?.changeWebcam(processedStream);
    }

    if (!localWebcamOn) {
      toggleState({ webcamOn: true });
      tracking.trackEvent('enable_video', isDoctor ? 'doctor' : 'patient', {
        micOn: useGlobalConfigStore.getState().micOn,
        webcamOn: true,
        action: 'main_participant_view_toolbox'
      });
    } else {
      toggleState({ webcamOn: false });
      tracking.trackEvent('disable_video', isDoctor ? 'doctor' : 'patient', {
        micOn: useGlobalConfigStore.getState().micOn,
        webcamOn: false,
        action: 'main_participant_view_toolbox'
      });
      if (isUseVirtualBackground(isDoctor)) {
        videoProcessor.current.stop();
      }
    }
  };

  useEffect(() => {
    // console.log('=====');
    const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
    const ifAudioTagsPresent = Array.from(document.getElementsByTagName('audio'));
    if (micRef.current) {
      // console.log('passing micref.current');
      try {
        if (!isFirefox) {
          // console.log('Setting SINKID ==> ');
          const speakerPresent = speakerList.find(speaker => speaker.deviceId === selectedSpeaker);
          if (!speakerPresent) {
            setSelectedSpeaker(speakerList[0].deviceId);
            ifAudioTagsPresent.forEach(tag => {
              tag.setSinkId(speakerList[0].deviceId);
            });
          }
          // micRef.current.setSinkId(selectedSpeaker.deviceId);
          // console.log('sinkID done+++++++++');
        }
      } catch (err) {
        console.log('Setting speaker device failed', err);
      }
    }
  }, [speakerList, micRef.current]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn) {
        try {
          const mediaStream = new MediaStream();
          mediaStream.addTrack(micStream.track);

          micRef.current.srcObject = mediaStream;
          if (selectedSpeaker) {
            micRef.current.setSinkId(selectedSpeaker);
          }
          micRef.current.play().catch(error => console.error('micRef.current.play() failed', error));
        } catch (err) {
          return; // do nothing
        }
      }
    }
  }, [micStream, micOn, micRef.current, localMicOn, meeting]);

  useEffect(() => {
    if (meeting.isJoined) {
      const messageToSent = {
        message: {
          status: CONSULTATION_STATUS.IN_CONSULTATION,
          isDoctor: isDoctor
        },
        type: 'message'
      };
      publish(JSON.stringify(messageToSent), { persist: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meeting.isJoined]);

  useEffect(() => {
    videoProcessor.current = new VirtualBackgroundProcessor();
  }, []);

  const shouldShowSmallContainer = useMemo(() => {
    if (!hasMoreParticipant && !toggleView) return false;
    if (hasMoreParticipant) return !toggleView;
  }, [hasMoreParticipant, toggleView]);

  const mainScreenClass = cn('video-container', {
    'video-container-small': shouldShowSmallContainer
  });

  const playerWrapper = cn('player-wrapper', {
    'video-container-small-wrapper': shouldShowSmallContainer
  });

  const participantContainerClass = cn('video-container', {
    'video-mobile-resolution': true
  });

  const videoConfig = {
    playsinline: true, // very very imp prop
    pip: false,
    light: false,
    controls: false,
    muted: true,
    playing: true
  };

  const usernamePreviewVideo = useMemo(() => {
    const currentParticipant = isDoctor ? doctor : patient;
    return isDoctor
      ? currentParticipant?.title
      : [currentParticipant?.first_name, currentParticipant?.last_name].join(' ');
  }, [isDoctor, doctor, patient]);

  const remoteUsernamePreviewVideo = useMemo(() => {
    const remoteParticipant = isDoctor ? patient : doctor;

    return isDoctor
      ? [remoteParticipant?.first_name, remoteParticipant?.last_name].join(' ')
      : remoteParticipant?.title;
  }, [doctor, isDoctor, patient]);

  const firstCharacterName = usernamePreviewVideo?.split('')?.[0];

  const heightActiveScreen = shouldShowSmallContainer ? 'auto' : '100%'; // set height local video player based on toggleView and participants join

  const status = useMemo(
    () => (isDoctor ? participantStatus?.patient : participantStatus?.practitioner),
    [isDoctor, participantStatus?.patient, participantStatus?.practitioner]
  );

  const profilePicture = useMemo(() => {
    const remoteUser = !isDoctor ? doctor : patient;
    return isDoctor ? remoteUser?.photo : remoteUser?.profilePicture;
  }, [doctor, isDoctor, patient]);

  const togglePiP = async () => {
    try {
      const videoElement = webcamRef.current.getInternalPlayer();
      if (videoElement.requestPictureInPicture) {
        await videoElement.requestPictureInPicture();
      } else {
        console.log('PiP not supported on this video Element');
      }
    } catch (err) {
      console.log('Failed to enter PiP mode', err);
    }
  };

  return (
    <>
      <div id="videoconference_page" className="video-wrapper">
        {networkBarEnabled && !isLocal && (
          <IndicatorsRemoteParticipant participantId={participantId} togglePiP={togglePiP} />
        )}
        {micOn && micRef && <audio ref={micRef} autoPlay muted={isLocal || Boolean(leavedScreenStates)} />}

        {isLocal && (
          <ToolBox
            className="toolbox toolbox-wrapper toolbox-overlay"
            micOn={micOn}
            webcamOn={webcamOn}
            toggleView={toggleView}
            handleToggleMic={handleLocalToggleMic}
            onClickScreenShare={async () => {
              meeting?.toggleScreenShare();
            }}
            handleToggleWebcam={handleLocalToggleWebcam}
            handleLeave={handleLeave}
            handleView={setToggleView}
            handleChangeCamera={handleChangeCamera}
            handleChangeMic={handleChangeMic}
            handleChangeSpeaker={handleChangeSpeaker}
            handleShowDeviceList={handleShowDeviceList}
            handleShowMicDeviceList={handleShowMicDeviceList}
            handleShowSpeakerDeviceList={handleShowSpeakerDeviceList}
            showDeviceList={toggleShowDeviceList}
            showMicList={toggleShowMicDeviceList}
            showSpeakerList={toggleShowSpeakerDeviceList}
            deviceLists={webCamList}
            speakerList={speakerList}
            showScreenShare={isDoctor}
            micLists={micList}
            selectedSpeaker={selectedSpeaker}
            setSelectedSpeaker={setSelectedSpeaker}
            handleClickOutside={handleClickOutside}
            hasMultipleSources
            showSwitchView
            showEndCall
          />
        )}

        {/* Screens Participant */}
        {isLocal && (!screenShareOn || !toggleView) && (
          <div className={mainScreenClass}>
            <div className={playerWrapper} style={{ position: 'relative' }}>
              <IndicatorsLocalParticipant
                participantId={participantId}
                shouldShowSmallContainer={shouldShowSmallContainer}
              />
              {!globalWebcamOn && (
                <div className="video-preview-wrapper">
                  {shouldShowSmallContainer ? (
                    <div className="video-preview">
                      <div className="video-preview--avatar">
                        <span>{firstCharacterName}</span>
                      </div>
                      <div className="video-preview--username">{usernamePreviewVideo}</div>
                    </div>
                  ) : (
                    <div className="video-preview-main">
                      <img
                        alt="participant-preview"
                        src={profilePicture ?? DoctorPreview}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = DoctorPreview;
                        }}
                        height={'auto'}
                        width={isMobile() ? 120 : 180}
                      />
                    </div>
                  )}
                </div>
              )}
              <VideoPlayer
                ref={webcamRef}
                wrapper={({ children }) => children}
                {...videoConfig}
                playIcon={<></>}
                url={webcamMediaStreamLocal}
                width="100%"
                height={heightActiveScreen}
                onError={err => {
                  console.log(err, 'participant video error');
                }}
              />
            </div>
          </div>
        )}
        {!isLocal && !screenShareOn && (
          <div className={participantContainerClass}>
            {!webcamOn && (
              <div className="video-preview-wrapper">
                <div className="main-video-cam--preview">
                  {status === CONSULTATION_STATUS.YET_TO_JOIN ? (
                    <h3>
                      {t(`Please wait {{participantName}} joins the call`, {
                        participantName: remoteUsernamePreviewVideo
                      })}
                    </h3>
                  ) : (
                    <img
                      alt="participant-preview"
                      src={profilePicture ?? DoctorPreview}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = DoctorPreview;
                      }}
                      height={'auto'}
                      width={isMobile() ? 120 : 180}
                    />
                  )}
                </div>
              </div>
            )}
            {!isLocal && !screenShareOn && (
              <VideoPlayer
                ref={webcamRef}
                {...videoConfig}
                playIcon={<></>}
                wrapper={({ children }) => children}
                url={webcamMediaStream}
                width="100%"
                height="100%"
                className="main-video-cam"
                onError={err => {
                  console.log(err, 'participant video error');
                }}
                onProgress={checkAndUpdatePortrait}
              />
            )}
          </div>
        )}
        {screenShareOn && (
          <div className={participantContainerClass}>
            <VideoPlayer
              ref={screenShareRef}
              {...videoConfig}
              playIcon={<></>}
              wrapper={({ children }) => children}
              url={screenShareMediaStream}
              width="100%"
              height="initial"
              className="main-video-cam"
              onError={err => {
                console.log(err, 'participant video error');
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

const ParticipantsView = ({
  participantType,
  setShowFullChat,
  setShowVideo,
  setShowChat,
  setShowInfo,
  setIsUnread
}) => {
  const {
    value: { isDoctor }
  } = useChat();
  const tracking = useTracking({
    from: 'ParticipantsView'
  });
  const setVideoEnabled = useGlobalConfigStore(state => state.setVideoEnabled);
  const setMicEnabled = useGlobalConfigStore(state => state.setMicEnabled);
  const [micList, setMicList] = useState([]);
  const [webCamList, setWebCamList] = useState([]);
  const [speakerList, setSpeakerList] = useState([]);
  const [selectedSpeaker, setSelectedSpeaker] = useState();
  const mMeetingRef = useRef();
  const videoRef = useRef();
  // const videoDoctorRef = useRef();
  const { getPlaybackDevices } = useMediaDevice();
  const initCam = async () => {
    const getWebcams = await meeting?.getWebcams();
    const webCams =
      getWebcams?.map((webcam, index) => {
        const label = !webcam?.label ? `Video ${index + 1}` : webcam.label;

        return { deviceId: webcam.deviceId, label: label };
      }) ?? [];
    setWebCamList(webCams);
  };

  const initMic = async () => {
    const getMics = await meeting?.getMics();
    const mics = getMics?.map((mic, index) => {
      const label = !mic.label ? `Audio ${index + 1}` : mic.label;

      return { deviceId: mic.deviceId, label: label };
    });
    setMicList(mics);

    const getSpeakers = await getPlaybackDevices();
    const speakers = getSpeakers?.map((speaker, index) => {
      const label = !speaker.label ? `Speaker ${index + 1}` : speaker.label;

      return { deviceId: speaker.deviceId, label: label };
    });
    setSelectedSpeaker(speakers[0]?.deviceId);
    setSpeakerList(speakers);
  };

  const onMeetingJoined = async () => {
    initMic();
    initCam();

    const micValue = getStorage('MicOn');
    const webCamValue = getStorage('WebCamOn');
    setVideoEnabled(webCamValue);
    setMicEnabled(micValue);
  };

  const onMeetingLeft = useCallback(() => {
    const micValue = getStorage('MicOn');
    const webCamValue = getStorage('WebCamOn');
    tracking.trackEvent('leave_room', isDoctor ? 'doctor' : 'patient', {
      micOn: micValue,
      webcamOn: webCamValue,
      action: 'meeting_left'
    });
  }, [tracking, isDoctor]);

  const meeting = useVideoSDK({
    onMeetingJoined,
    onMeetingLeft
  });
  const [toggleView, setToggleView] = useState();

  const switchToChat = ({ micOn, webcamOn }) => {
    const isMobile = mobileBreakPoint();
    setShowFullChat(true);
    setShowVideo(false);
    setShowChat(false);
    setShowInfo(!isMobile);
    micOn && meeting.toggleMic();
    webcamOn && meeting.toggleWebcam();
    setIsUnread(false);
  };

  const setLayout = () => {
    let layoutView = 'vertical-view';
    if (meeting.participants.size === 1) {
      return layoutView;
    }

    if (toggleView) {
      layoutView = 'tile-view';
    }

    return layoutView;
  };

  useEffect(() => {
    mMeetingRef.current = meeting;
  }, [meeting]);

  return (
    <>
      {chunk([...meeting.participants.keys()]).map(k => (
        <Fragment key={k}>
          <div className={`video-layout ${setLayout()} ${meeting.participants.size === 1 ? 'single' : ''}`}>
            {k.map(l => (
              <ParticipantView
                key={l}
                hasMoreParticipant={+meeting.participants.size > 1}
                participantId={l}
                videoRef={videoRef}
                participantType={participantType}
                leave={switchToChat}
                toggleMic={meeting.toggleMic}
                toggleWebcam={meeting.toggleWebcam}
                toggleView={toggleView}
                setToggleView={setToggleView}
                isScreenSharing={!!meeting.presenterId}
                webCamList={webCamList}
                micList={micList}
                speakerList={speakerList}
                selectedSpeaker={selectedSpeaker}
                setMicList={setMicList}
                setWebCamList={setWebCamList}
                setSpeakerList={setSpeakerList}
                setSelectedSpeaker={setSelectedSpeaker}
              />
            ))}
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default ParticipantsView;
