import { createCameraVideoTrack, createMicrophoneAudioTrack } from '@videosdk.live/react-sdk';
import ConferenceAPI from 'libs/api/conference';
import { getStorage, setStorage } from 'libs/storage';
import { getQueryParams } from 'libs/url';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useGlobalConfigStore } from 'zustandStore';

const MAX_DECIBELS = 127;

const captureImageFromMediastreamTrack = track => {
  return new Promise((resolve, reject) => {
    const imageCapture = new ImageCapture(track);
    imageCapture
      .takePhoto()
      .then(blob => {
        const image = new Image();
        image.src = URL.createObjectURL(blob);
        image.onload = () => {
          resolve(image);
        };
        image.onerror = error => {
          reject(error);
        };
      })
      .catch(error => {
        reject(error);
      });
  });
};

const getMediaStreamTrack = async deviceId => {
  const mediaStream = await createCameraVideoTrack({
    cameraId: deviceId,
    resolution: 'hd',
    frameRate: 30
  });
  const track = mediaStream.getVideoTracks()[0];
  if (track) {
    return track;
  }
  return false;
};

const getCurrentUserMicrophone = async id => {
  const audioTrack = await createMicrophoneAudioTrack({
    microphoneId: id,
    noiseConfig: {
      echoCancellation: true
    }
  });
  const track = audioTrack.getAudioTracks()[0];
  if (track) {
    return track;
  }
  return false;
};

const getVolumeLevel = async (analyser, maxDecibels) => {
  const volumes = new Uint8Array(analyser.frequencyBinCount);
  analyser.getByteFrequencyData(volumes);
  let volumeSum = 0;
  for (const volume of volumes) {
    volumeSum += volume;
  }
  const averageVolume = volumeSum / volumes.length;
  return Math.floor((averageVolume * 100) / maxDecibels);
};

const setupAudioCapture = async (micDeviceId, maxDecibels) => {
  const audioTrack = await getCurrentUserMicrophone(micDeviceId);
  if (!audioTrack) {
    return 0;
  }
  const audioStream = new MediaStream([audioTrack]);
  const audioContext = new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(audioStream);
  const analyser = audioContext.createAnalyser();
  analyser.fftSize = 512;
  analyser.minDecibels = 0 - maxDecibels;
  analyser.maxDecibels = 0;
  analyser.smoothingTimeConstant = 0.4;
  audioSource.connect(analyser);
  return [analyser, audioSource];
};

const useTracking = ({
  from = "useTracking"
}) => {
  const location = useLocation();
  const params = getQueryParams(location);

  const appointmentNumber = params?.id;
  const timerHandle = useRef(null);
  const audioSourceRef = useRef(null);
  const analyserRef = useRef(null);
  const micDeviceId = useGlobalConfigStore(state => state.micDeviceId);
  const webcamDeviceId = useGlobalConfigStore(state => state.webcamDeviceId);
  const micOn = getStorage('MicOn');

  const trackCapabilities = (eventName, participantType, { micOn, webcamOn, ...restEvent }) => {
    let trackingData = {};
    if (useGlobalConfigStore) {
      const audioTrack = useGlobalConfigStore.getState().audio_track;
      const videoTrack = useGlobalConfigStore.getState().video_track;
      const micDeviceId = useGlobalConfigStore.getState().micDeviceId;
      const webcamDeviceId = useGlobalConfigStore.getState().webcamDeviceId;
      const audioLevel = getStorage('audioLevel');
      const videoSizeWidth = getStorage('video_width');
      const videoSizeHeight = getStorage('video_height');

      trackingData = {
        appointment_number: appointmentNumber,
        event_name: eventName,
        participant: participantType,
        state: {
          audio_captured: audioLevel > 0,
          audio_captured_level: audioLevel,
          videoSize: {
            width: videoSizeWidth,
            height: videoSizeHeight,
          },
          remote_audio_captured: audioTrack !== null,
          local_video_captured: videoTrack !== null,
          audio_current_state: micOn,
          video_current_state: webcamOn,
          audio_track: audioTrack,
          video_track: videoTrack,
          active_audio_track: (audioTrack || []).find(track => track.deviceId === micDeviceId)?.label || '',
          active_video_track: (videoTrack || []).find(track => track.deviceId === webcamDeviceId)?.label || '',
          audio_permission: audioTrack !== null,
          video_permission: videoTrack !== null,
          ...restEvent
        },
        event_timestamp: new Date().toISOString()
      };
    }
    return ConferenceAPI.trackEvent(trackingData);
  };

  const getCurrentAudioLevel = useCallback(
    async (micDeviceId) => {
      if (!micDeviceId) {
        return;
      }
      const [analyser, audioSource] = await setupAudioCapture(micDeviceId, MAX_DECIBELS);
      audioSourceRef.current = audioSource;
      analyserRef.current = analyser;
      timerHandle.current = setInterval(async () => {
        const audioLevel = await getVolumeLevel(analyser, MAX_DECIBELS);
        setStorage('audioLevel', audioLevel);
      }, 1000);
    }, []
  );

  useEffect(() => {
    if (micDeviceId !== null && micOn) {
      getCurrentAudioLevel(micDeviceId, from);
    }
    const audioSource = audioSourceRef.current;
    const analyser = analyserRef.current;
    return () => {
      audioSource?.disconnect();
      analyser?.disconnect();
      clearInterval(timerHandle.current);
    };
  }, [micDeviceId, getCurrentAudioLevel, micOn, from]);

  const getCurrentWebcamImage = useCallback(
    async webcamDeviceId => {
      try {
        const track = await getMediaStreamTrack(webcamDeviceId);
        if (track) {
          const image = await captureImageFromMediastreamTrack(track);
          setStorage('video_width', image.width);
          setStorage('video_height', image.height);
          image.close();
        }
      } catch (error) {
        // console.log('Failed to capture webcam image', error);
      }
    },
    []
  );

  useEffect(() => {
    if (webcamDeviceId !== null) {
      getCurrentWebcamImage(webcamDeviceId);
    }
  }, [webcamDeviceId, getCurrentWebcamImage]);
  return {
    trackEvent: async (eventName, participantType, { micOn, webcamOn, ...restEvent }) => {
      return trackCapabilities(eventName, participantType, { micOn, webcamOn, ...restEvent });
    }
  };
};

export default useTracking;
