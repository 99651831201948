import React from 'react';
import { Microphone, MicrophoneSlash, VideoCamera, VideoCameraSlash, SquaresFour, Phone, SpeakerHigh } from 'phosphor-react';

const ToolboxIcon = ({ ariaLabel, active, iconActive, iconNonActive, toggleIcon, hasMultipleSources = false, onDeviceListClick, children, ...props }) => {
  const icons = {
    Microphone,
    MicrophoneSlash,
    VideoCamera,
    VideoCameraSlash,
    SquaresFour,
    Phone,
    SpeakerHigh,
  };

  const Icon = props => {
    const { icon } = props;

    const TheIcon = icons[icon];
    return <TheIcon {...props} />;
  };

  return (
    <div className="toolbox-button-container">
      <div aria-label={ariaLabel} className="toolbox-button" role="button" {...props}>
        <div className={`toolbox-icon ${toggleIcon ? 'toggled' : ''}`}>
          {active ? (
            <Icon icon={iconActive} size={20} weight="bold" />
          ) : (
            <Icon icon={iconNonActive} size={20} weight="bold" />
          )}
        </div>
        { hasMultipleSources && (
          <div onClick={onDeviceListClick} className="settings-button-small-icon" id="video-settings-button" role="button">
            <svg fill="none" height="9" width="9" viewBox="0 0 10 7"><path clipRule="evenodd" d="M8.411 6.057A.833.833 0 109.65 4.943L5.73.563a.833.833 0 00-1.24 0L.63 4.943a.833.833 0 001.24 1.114l3.24-3.691L8.41 6.057z"></path></svg>
          </div>
          )
        }
      </div>
      { children }
    </div>
  );
};

export default ToolboxIcon;
