import React from 'react';
import PropTypes from 'prop-types';
import ToolboxIcon from './ToolboxIcon';
import { mobileBreakPoint } from 'libs/okaBrowserCheck';
import { CheckCircle } from 'phosphor-react';
import { useGlobalConfigStore } from 'zustandStore';
import AudioPreview from './audio-preview';
import useClickOutside from 'libs/hooks/useClickOutside';

const Toolbox = ({
  micOn,
  webcamOn,
  toggleView,
  handleToggleMic,
  handleToggleWebcam,
  handleLeave,
  handleView,
  handleShowDeviceList,
  handleShowMicDeviceList,
  handleShowSpeakerDeviceList,
  handleChangeCamera,
  onClickScreenShare,
  handleChangeMic,
  handleChangeSpeaker,
  hasMultipleSources = false,
  showDeviceList = false,
  showMicList = false,
  showSpeakerList = false,
  showSwitchView,
  showScreenShare,
  showEndCall,
  deviceLists,
  showAudioPreview = false,
  micLists,
  speakerList,
  selectedSpeaker,
  setSelectedSpeaker,
  handleClickOutside,
  ...props
}) => {
  const webcamDeviceId = useGlobalConfigStore(state => state.webcamDeviceId);
  const isMobile = mobileBreakPoint();
  const micDeviceId = useGlobalConfigStore(state => state.micDeviceId);
  const listboxRef = useClickOutside(handleClickOutside);
  const meetingStarted = useGlobalConfigStore(state => state.meetingStarted);

  const DeviceList = ({ dataLists = [], showLists }) => {
    return (
      <>
        {showLists &&
          dataLists.length > 0 &&
          dataLists.map((k, index) => (
            <div
              key={index}
              className={'source-entry ' + (k.deviceId === webcamDeviceId ? 'source-entry--selected' : '')}
              onClick={() => handleChangeCamera(k.deviceId)}
              role="radio"
              aria-checked="true"
            >
              {k.deviceId === webcamDeviceId && (
                <div className="source-entry-icon">
                  <CheckCircle size={14} color="#31b76a" weight="fill" />
                </div>
              )}

              <div className="source-entry-label">
                <span>{k.label}</span>
              </div>
            </div>
          ))}
      </>
    );
  };

  const MicList = ({ dataLists = [], showLists }) => {
    return (
      <>
        {showLists &&
          dataLists.length > 0 &&
          dataLists.map((k, index) => (
            <div
              key={`mic-${index}`}
              className={'source-entry ' + (k.deviceId === micDeviceId ? 'source-entry--selected' : '')}
              onClick={() => handleChangeMic(k.deviceId)}
              role="radio"
              aria-checked="true"
            >
              {k.deviceId === micDeviceId && (
                <div className="source-entry-icon">
                  <CheckCircle size={14} color="#31b76a" weight="fill" />
                </div>
              )}

              <div className="source-entry-label">
                <span>{k.label}</span>
              </div>
            </div>
          ))}
      </>
    );
  };

  const SpeakerList = ({ dataLists = [], showLists }) => {
    return (
      <>
        {showLists &&
          dataLists.length > 0 &&
          dataLists.map((k, index) => (
            <div
              key={`speaker-${index}`}
              className={'source-entry ' + (k.deviceId === selectedSpeaker ? 'source-entry--selected' : '')}
              onClick={() => handleChangeSpeaker(k.deviceId)}
              role="radio"
              aria-checked="true"
            >
              {k.deviceId === selectedSpeaker && (
                <div className="source-entry-icon">
                  <CheckCircle size={14} color="#31b76a" weight="fill" />
                </div>
              )}

              <div className="source-entry-label">
                <span>{k.label}</span>
              </div>
            </div>
          ))}
      </>
    );
  };

  return (
    <div className="toolbox toolbox-wrapper" {...props}>
      <div className="toolbox-content" ref={listboxRef}>
        {!isMobile && meetingStarted && (
          <ToolboxIcon
            ariaLabel="Speakers"
            active={true}
            iconActive="SpeakerHigh"
            hasMultipleSources={hasMultipleSources}
            onDeviceListClick={handleShowSpeakerDeviceList}
          >
            <div className="toolbox-source-list">
              <SpeakerList dataLists={speakerList} showLists={showSpeakerList} />
            </div>
          </ToolboxIcon>
        )}
        {showAudioPreview && <AudioPreview micEnabled={micOn} />}
        <ToolboxIcon
          onClick={handleToggleMic}
          ariaLabel="Mute / Unmute"
          active={micOn}
          iconActive="Microphone"
          iconNonActive="MicrophoneSlash"
          hasMultipleSources={hasMultipleSources}
          onDeviceListClick={handleShowMicDeviceList}
        >
          <div className="toolbox-source-list">
            <MicList dataLists={micLists} showLists={showMicList} />
          </div>
        </ToolboxIcon>

        <ToolboxIcon
          onClick={handleToggleWebcam}
          ariaLabel="Start / Stop camera"
          active={deviceLists?.length && webcamOn}
          iconActive="VideoCamera"
          iconNonActive="VideoCameraSlash"
          hasMultipleSources={hasMultipleSources}
          onDeviceListClick={handleShowDeviceList}
        >
          <div className="toolbox-source-list">
            <DeviceList dataLists={deviceLists} showLists={showDeviceList} />
          </div>
        </ToolboxIcon>

        {showSwitchView && (
          <ToolboxIcon
            onClick={() => handleView(prevCheck => !prevCheck)}
            ariaLabel="Toggle tile view"
            active={showSwitchView}
            toggleIcon={toggleView}
            iconActive="SquaresFour"
          />
        )}

        {showScreenShare && !isMobile && (
          <div className="toolbox-button-container">
            <div className="toolbox-button" role="button">
              <div onClick={onClickScreenShare} className="toolbox-icon">
                <img
                  src="https://img.okadoc.com/photos/block_images/img/icon/screen-share.svg"
                  alt="screen share icon"
                  className="screen-share-icon"
                />
              </div>
            </div>
          </div>
        )}

        {showEndCall && (
          <ToolboxIcon
            onClick={handleLeave}
            className="toolbox-button toolbox-button-hangup"
            ariaLabel="Leave the meeting"
            active={showEndCall}
            iconActive="Phone"
          />
        )}
      </div>
    </div>
  );
};

Toolbox.propTypes = {
  handleToggleMic: PropTypes.func.isRequired,
  handleToggleWebcam: PropTypes.func.isRequired
};

export default Toolbox;